// src/components/DashboardMetrics.js
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';

import {
  Chart as ChartJS,
  BarController,
  BarElement,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

ChartJS.register(
  BarController,
  BarElement,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement
);

// Exportamos el mapeo de colores
export const statusColors = {
  Entregado: '#74BEC1',
  Enviado: '#F67280',
  Cancelado: '#F8B195',
  Devolución: '#ADEBBE',
  Pendiente: '#9AB5C1',
  Otro: '#EEF3AD',
};

const DashboardMetrics = ({ startDate, endDate }) => {
  const [orderStatusData, setOrderStatusData] = useState({});
  const [ordersInRange, setOrdersInRange] = useState(0);
  const [salesInRange, setSalesInRange] = useState(0);
  const [lineChartData, setLineChartData] = useState({ labels: [], ordersData: [], salesData: [] });
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedPurchased, setSelectedPurchased] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const params = {
          startDate: startDate || '2000-01-01',
          endDate: endDate || new Date().toISOString().split('T')[0],
          status: selectedStatus,
          purchased: selectedPurchased,
        };

        console.log('Parámetros enviados:', params);

        const response = await axios.get('/api/metrics', { params });

        console.log('Datos recibidos:', response.data);

        setOrderStatusData({
          labels: response.data.orderStatus.labels,
          datasets: [
            {
              data: response.data.orderStatus.data,
              backgroundColor: response.data.orderStatus.labels.map(
                (label) => statusColors[label] || '#FFFFFF'
              ),
            },
          ],
        });

        setOrdersInRange(response.data.ordersInRange);
        setSalesInRange(response.data.salesInRange);

        setLineChartData({
          labels: response.data.lineChart.labels.map((date) => new Date(date)),
          ordersData: response.data.lineChart.ordersData,
          salesData: response.data.lineChart.salesData,
        });
      } catch (error) {
        console.error('Error al obtener los datos del dashboard:', error);
      }
    };

    fetchMetrics();
  }, [startDate, endDate, selectedStatus, selectedPurchased]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });

    const sortedData = [...lineChartData.labels]
      .map((label, index) => ({
        date: label,
        orders: lineChartData.ordersData[index],
        sales: lineChartData.salesData[index],
      }))
      .sort((a, b) => {
        if (key === 'date') {
          return direction === 'asc' ? a.date - b.date : b.date - a.date;
        } else {
          return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
        }
      });

    setLineChartData({
      labels: sortedData.map((item) => item.date),
      ordersData: sortedData.map((item) => item.orders),
      salesData: sortedData.map((item) => item.sales),
    });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '';
  };

  const cardBodyStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
  };

  const tableContainerStyle = {
    height: '300px',
    overflowY: 'auto',
  };

  return (
    <Container fluid className="dashboard-metrics mb-4">
      <Row>
        <Col md={6}>
          <Card className="mb-3">
            <Card.Body style={cardBodyStyle}>
              <div>
                <Card.Title>Total Órdenes</Card.Title>
                <h2>{ordersInRange}</h2>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-3">
            <Card.Body style={cardBodyStyle}>
              <div>
                <Card.Title>Total Ventas</Card.Title>
                <h2>{formatCurrency(salesInRange)}</h2>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <Card className="mb-3 h-100">
            <Card.Body style={cardBodyStyle}>
              <div style={{ width: '100%' }}>
                <Card.Title>Órdenes por Estado</Card.Title>
                {orderStatusData.labels &&
                orderStatusData.labels.length > 0 &&
                orderStatusData.datasets[0].data.length > 0 ? (
                  <div style={{ height: '300px', overflow: 'hidden' }}>
                    <Pie
                      data={orderStatusData}
                      options={{
                        maintainAspectRatio: true,
                        animation: false,
                        plugins: {
                          legend: {
                            position: 'top',
                            align: 'center',
                            labels: {
                              boxWidth: 20,
                              padding: 20,
                              usePointStyle: true,
                              font: {
                                size: 12,
                              },
                            },
                          },
                          tooltip: {
                            callbacks: {
                              label: function (tooltipItem) {
                                return `${orderStatusData.labels[tooltipItem.dataIndex]}: ${tooltipItem.raw}`;
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                ) : (
                  <p>No hay datos disponibles</p>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={8}>
          <Card className="mb-3 h-100">
            <Card.Body style={cardBodyStyle}>
              <div style={{ width: '100%' }}>
                <Card.Title>Órdenes y Ventas por Día</Card.Title>
                {lineChartData.labels && lineChartData.labels.length > 0 && (
                  <div style={tableContainerStyle}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th onClick={() => sortData('date')}>
                            Fecha {getSortIcon('date')}
                          </th>
                          <th onClick={() => sortData('orders')}>
                            Órdenes {getSortIcon('orders')}
                          </th>
                          <th onClick={() => sortData('sales')}>
                            Ventas {getSortIcon('sales')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {lineChartData.labels.map((date, index) => (
                          <tr key={index}>
                            <td>
                              {date.toLocaleDateString('es-CL', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })}
                            </td>
                            <td>{lineChartData.ordersData[index]}</td>
                            <td>{formatCurrency(lineChartData.salesData[index])}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardMetrics;
