import React, { useState, useEffect, useRef } from 'react';

const OrderScannerDesktop = () => {
    const [scannedOrders, setScannedOrders] = useState([]);
    const [trackingCode, setTrackingCode] = useState('');
    const inputRef = useRef(null); // Para manejar el foco automático en el input

    // Foco automático en el campo de entrada
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus(); // Foco en el campo de entrada al montar el componente
        }
    }, []);

    const handleScan = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const code = trackingCode.trim();
            setTrackingCode('');

            // Llamada al backend para obtener los detalles de la orden basada en el trackingCode
            const response = await fetch(`/api/orderDetails/${code}`);
            const data = await response.json();

            if (response.status === 404 || !data) {
                alert('Orden no encontrada para este código.');
            } else {
                // Agregar la nueva fila a la tabla
                setScannedOrders((prevOrders) => [
                    ...prevOrders,
                    {
                        order_number: data.order_number,
                        item: data.items.map((item) => item.name).join(', '),
                        fecha_compromiso: data.promised_shipping_time,
                        estado: data.status,
                        cantidad: data.items_count
                    }
                ]);
            }
        }
    };

    // Función para actualizar el estado de las órdenes a "Listo para Entregar"
    const handleStatusUpdate = async () => {
        let readyToShipCount = 0;
    
        for (let order of scannedOrders) {
            if (order.estado.toLowerCase() === 'pending') {
                try {
                    // Llamar al backend para actualizar el estado a "Listo para Despachar"
                    const response = await fetch(`/api/setReadyToShipStatus/${order.order_number}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
    
                    if (!response.ok) {
                        throw new Error('Error en la respuesta del servidor.');
                    }
    
                    readyToShipCount++;
                } catch (error) {
                    alert(`Error al procesar la orden ${order.order_number}: ${error.message}`);
                }
            } else {
                alert(`La orden ${order.order_number} no está en estado "Pendiente", su estado es ${order.estado}.`);
            }
        }
    
        alert(`Se pusieron ${readyToShipCount} órdenes en estado "Listo para Entregar".`);
    };
    

    // Función para resetear la lista de órdenes escaneadas
    const handleReset = () => {
        setScannedOrders([]); // Limpiar las órdenes escaneadas
        setTrackingCode('');  // Limpiar el campo de entrada
        if (inputRef.current) {
            inputRef.current.focus(); // Reenfocar el campo de entrada
        }
    };

    return (
        <div className="container mt-4">
            <h1 className="text-center mb-4">Escaneo de Órdenes - Escritorio</h1>
            <input
                type="text"
                value={trackingCode}
                ref={inputRef} // Enfocar automáticamente el input
                onChange={(e) => setTrackingCode(e.target.value)}
                onKeyPress={handleScan}
                placeholder="Escanea un código de barras"
                className="form-control mb-3"
            />

            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Nº de Orden</th>
                        <th>Item</th>
                        <th>Fecha Compromiso</th>
                        <th>Estado</th>
                        <th>Cantidad de Ítems</th>
                    </tr>
                </thead>
                <tbody>
                    {scannedOrders.map((order, index) => (
                        <tr key={index}>
                            <td>{order.order_number}</td>
                            <td>{order.item}</td>
                            <td>{new Date(order.fecha_compromiso).toLocaleDateString()}</td>
                            <td>{order.estado}</td>
                            <td>{order.cantidad}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="d-flex justify-content-between">
                <button className="btn btn-primary mt-3" onClick={handleStatusUpdate}>
                    Actualizar Estado de Órdenes a "Listo para Entregar"
                </button>
                <button className="btn btn-danger mt-3" onClick={handleReset}>
                    Resetear Escaneo
                </button>
            </div>
        </div>
    );
};

export default OrderScannerDesktop;
