import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function OrderFilters({
    searchTerm,
    setSearchTerm,
    selectedStatus,
    setSelectedStatus,
    selectedPurchased,
    setSelectedPurchased,
    dateRange,
    setDateRange,
    sortOrder,
    setSortOrder,
    handleSearch // Recibe la función de búsqueda desde el componente padre
}) {
    const [startDate, endDate] = dateRange;

    // Función para manejar la búsqueda al presionar "Enter"
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch(); // Llama a la función de búsqueda
        }
    };

    return (
        <Form>
            <Row className="align-items-center mb-3">
                <Col xs={12} md={3}>
                    <Form.Control
                        type="text"
                        placeholder="Buscar por número de orden o cliente"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={handleKeyDown}  // Ejecuta la búsqueda al presionar "Enter"
                    />
                </Col>
                <Col xs={12} md={2}>
                    <DatePicker
                        selectsRange
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => setDateRange(update)}
                        isClearable={true}
                        placeholderText="Seleccionar rango de fechas"
                        className="form-control"
                    />
                </Col>
                <Col xs={12} md={2}>
                    <Form.Control
                        as="select"
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        <option value="">Todos los estados</option>
                        <option value="pending">Pendiente</option>
                        <option value="delivered">Enviado</option>
                        <option value="canceled">Cancelado</option>
                        <option value="returned">Devuelto</option>
                    </Form.Control>
                </Col>
                <Col xs={12} md={2}>
                    <Form.Control
                        as="select"
                        value={selectedPurchased}
                        onChange={(e) => setSelectedPurchased(e.target.value)}
                    >
                        <option value="">Todos</option>
                        <option value="1">Comprados</option>
                        <option value="0">No Comprados</option>
                    </Form.Control>
                </Col>
                <Col xs={12} md={2}>
                    <Form.Control
                        as="select"
                        value={sortOrder.direction}
                        onChange={(e) => setSortOrder({ ...sortOrder, direction: e.target.value })}
                    >
                        <option value="desc">Más recientes</option>
                        <option value="asc">Más antiguos</option>
                    </Form.Control>
                </Col>
            </Row>
        </Form>
    );
}

export default OrderFilters;
