import React, { useState, useEffect } from 'react';
import Quagga from 'quagga';

const BarcodeScanner = () => {
    const [orderDetails, setOrderDetails] = useState(null);
    const [scanning, setScanning] = useState(true); // Estado para manejar si estamos escaneando o no

    const handleDetected = (code) => {
        console.log("Código escaneado: " + code);
        Quagga.stop(); // Detener escaneo
        setScanning(false); // Mostrar los detalles del pedido
        fetch(`/api/orderDetails/${code}`)
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    alert('No se encontró información para este código.');
                } else {
                    const shippingAddress = data.address || {};
                    setOrderDetails({
                        ...data,
                        customer_name: data.customer_name || "No disponible",
                        order_number: data.order_number || "No disponible",
                        items_count: data.items_count || 0,
                        status: data.status || "Desconocido",
                        grand_total: data.grand_total || 0,
                        promised_shipping_time: data.promised_shipping_time || "No disponible",
                        address: {
                            FirstName: shippingAddress.FirstName || "No disponible",
                            LastName: shippingAddress.LastName || "No disponible",
                            Address1: shippingAddress.Address1 || "",
                            Address2: shippingAddress.Address2 || "",
                            City: shippingAddress.City || "No disponible",
                            Region: shippingAddress.Region || "No disponible",
                        },
                        items: data.items || []
                    });
                }
            })
            .catch(error => {
                console.error('Error al obtener los detalles de la orden:', error);
            });
    };

    useEffect(() => {
        if (scanning) {
            Quagga.init({
                inputStream: {
                    name: "Live",
                    type: "LiveStream",
                    target: document.querySelector('#scanner-container'),
                    constraints: {
                        width: 640,
                        height: 480,
                        facingMode: "environment" // Cámara trasera
                    }
                },
                decoder: {
                    readers: ["ean_reader", "code_128_reader"]
                }
            }, function(err) {
                if (err) {
                    console.log(err);
                    return;
                }
                Quagga.start();
            });

            Quagga.onDetected(result => {
                const code = result.codeResult.code;
                handleDetected(code);
            });
        }

        return () => Quagga.stop();
    }, [scanning]);

    return (
        <div className="container mt-4">
            <style>
            {`
                html, body {
                    height: 100%;
                    margin: 0;
                    padding: 0;
                }

                #scanner-container {
                    width: 100%;
                    height: 300px;
                    max-width: 400px;
                    margin: 0 auto;
                    border: 2px solid black;
                }

                .container {
                    min-height: 100vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                #order-details {
                    margin-top: 20px;
                }

                button {
                    margin-top: 20px;
                    width: 100%;
                }

                .card {
                    margin-top: 20px;
                }
            `}
            </style>

            <h1 className="text-center mb-4">Escanear Código de Barras</h1>

            {scanning ? (
                <div className="d-flex justify-content-center">
                    <div id="scanner-container"></div>
                </div>
            ) : (
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8">
                        {orderDetails ? (
                            <div className="card mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">Detalles de la Orden</h5>
                                    <p><strong>Nombre del Cliente:</strong> {orderDetails.customer_name}</p>
                                    <p><strong>Número de Orden:</strong> {orderDetails.order_number}</p>
                                    <p><strong>Cantidad de Ítems:</strong> {orderDetails.items_count}</p>
                                    <p><strong>Estado:</strong> {orderDetails.status}</p>
                                    <p><strong>Total:</strong> ${orderDetails.grand_total}</p>
                                    <p><strong>Fecha Prometida de Envío:</strong> {new Date(orderDetails.promised_shipping_time).toLocaleString()}</p>
                                    <h6>Ítems:</h6>
                                    <ul className="list-group">
                                        {orderDetails.items.map(item => (
                                            <li key={item.sku} className="list-group-item">{item.name} (SKU: {item.sku})</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <p>Cargando información de la orden...</p>
                        )}

                        <div className="text-center">
                            <button className="btn btn-lg btn-primary" onClick={() => setScanning(true)}>Volver a escanear</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BarcodeScanner;
