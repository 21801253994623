import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import tinycolor from 'tinycolor2';
import { statusColors } from './DashboardMetrics'; // Asegúrate de que esta ruta es correcta

function OrderTable({
  orders,
  loading,
  expandedOrderId,
  toggleExpand,
  formatDateTime,
  handlePurchaseToggle,
  handleReadyToShip,
  sortOrder,
  setSortOrder,
}) {
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [labelUrl, setLabelUrl] = useState('');
  const [currentOrderNumber, setCurrentOrderNumber] = useState('');
  const [orderItems, setOrderItems] = useState([]);
  const [hoveredOrderId, setHoveredOrderId] = useState(null);

  useEffect(() => {
    const fetchProductUrls = async () => {
      // Mapear items de la orden para agregar las URLs de los productos
      const updatedItems = await Promise.all(
        orders.map(async (order) => {
          const updatedOrderItems = await Promise.all(
            order.items.map(async (item) => {
              try {
                const response = await axios.get(`/api/getProductUrl/${item.sku}`);
                return { ...item, productUrl: response.data.url }; // Añade la URL obtenida
              } catch (error) {
                console.error(`Error al obtener la URL para SKU ${item.sku}:`, error);
                return { ...item, productUrl: '#' }; // Valor por defecto si hay error
              }
            })
          );
          return { ...order, items: updatedOrderItems }; // Actualiza los items de la orden
        })
      );
      setOrderItems(updatedItems); // Actualiza el estado con los items y sus URLs
    };

    fetchProductUrls();
  }, [orders]);

  // Función para formatear precios en CLP
  const formatPrice = (price) => {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  // Función para previsualizar la etiqueta de envío
  const handlePreviewLabel = async (orderId, orderNumber) => {
    try {
      const response = await axios.get(`/api/getLabel/${orderId}`);
      console.log('Respuesta de getLabel:', response.data);

      const base64Data = response.data.File;
      const mimeType = response.data.MimeType || 'application/pdf';

      if (!base64Data) {
        throw new Error('La etiqueta no contiene datos.');
      }

      // Convertir la cadena Base64 a un blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const pdfBlob = new Blob([byteArray], { type: mimeType });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      setLabelUrl(pdfUrl);
      setCurrentOrderNumber(orderNumber);
      setShowLabelModal(true);

      // Actualizar el estado de la etiqueta en la base de datos y localmente
      const updatedOrders = orderItems.map((order) => {
        if (order.order_id === orderId) {
          axios.post('/api/updateOrderStatus', { orderId, etiqueta: 1, ready_to_ship_enabled: 1 });
          return { ...order, etiqueta: 1, ready_to_ship_enabled: 1 };
        }
        return order;
      });
      setOrderItems(updatedOrders);
    } catch (error) {
      console.error('Error al obtener la etiqueta:', error);
      alert('No se pudo cargar la etiqueta. Por favor, inténtalo de nuevo.');
    }
  };

  // Función para descargar la etiqueta
  const downloadLabel = () => {
    if (labelUrl) {
      const link = document.createElement('a');
      link.href = labelUrl;
      link.download = `${currentOrderNumber}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(labelUrl); // Liberar el objeto URL
    }
  };

  // Función para mapear el estado al label utilizado en el gráfico
  function getStatusLabel(status) {
    switch (status) {
      case 'delivered':
        return 'Entregado';
      case 'shipped':
        return 'Enviado';
      case 'canceled':
      case 'failed':
        return 'Cancelado';
      case 'returned':
      case 'return_waiting_for_approval':
        return 'Devolución';
      case 'pending':
        return 'Pendiente';
      default:
        return 'Otro';
    }
  }

  // Función para obtener el color al hacer hover
  const getHoverColor = (backgroundColor) => {
    return tinycolor(backgroundColor).darken(10).toString();
  };

  // Función para manejar el ordenamiento
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortOrder.key === key && sortOrder.direction === 'asc') {
      direction = 'desc';
    }
    setSortOrder({ key, direction });
  };

  // Función para obtener el ícono de ordenamiento
  const getSortIcon = (key) => {
    if (sortOrder.key === key) {
      return sortOrder.direction === 'asc' ? ' ▲' : ' ▼';
    }
    return '';
  };

  const renderItemsRow = (order) => {
    // Encuentra los items de esta orden desde el estado `orderItems`
    const currentOrderItems = orderItems.find((o) => o.order_id === order.order_id)?.items || order.items;

    return (
      <tr key={`${order.order_id}-details`} className="expanded-row">
        <td colSpan="11">
          <Table bordered style={{ width: '80%', margin: 'auto' }}>
            <thead>
              <tr>
                <th>Código de seguimiento</th>
                <th>Producto</th>
                <th>Cantidad</th>
                <th>SKU</th>
                <th>Precio</th>
              </tr>
            </thead>
            <tbody>
              {currentOrderItems.map((item) => (
                <tr key={item.order_item_id}>
                  <td>{item.tracking_code}</td>
                  <td>
                    {item.productUrl ? (
                      <a href={item.productUrl} target="_blank" rel="noopener noreferrer">
                        {item.name}
                      </a>
                    ) : (
                      item.name
                    )}
                  </td>
                  <td>{item.quantity}</td>
                  <td>
                    <a href={item.sku_url} target="_blank" rel="noopener noreferrer">
                      {item.sku}
                    </a>
                  </td>
                  <td>{formatPrice(item.price)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </td>
      </tr>
    );
  };

  if (loading) {
    return (
      <Table striped bordered hover className="mt-4" size="sm">
        <thead>
          <tr>
            <th></th>
            <th>Número de Orden</th>
            <th>Cliente</th>
            <th>Fecha de Compra</th>
            <th>Hora de Compra</th>
            <th>Fecha Compromiso</th>
            <th style={{ width: '10%' }}>Estado</th>
            <th style={{ width: '15%' }}>Precio</th>
            <th>Comprado</th>
            <th>Etiqueta</th>
            <th>Listo para despachar</th>
          </tr>
        </thead>
        <tbody>
          {[...Array(10)].map((_, i) => (
            <tr key={i}>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <>
      <Table striped bordered hover className="mt-4" size="sm">
        <thead>
          <tr>
            <th></th>
            <th onClick={() => handleSort('order_number')} style={{ cursor: 'pointer' }}>
              Número de Orden{getSortIcon('order_number')}
            </th>
            <th>Cliente</th>
            <th onClick={() => handleSort('created_at')} style={{ cursor: 'pointer' }}>
              Fecha de Compra{getSortIcon('created_at')}
            </th>
            <th>Hora de Compra</th>
            <th onClick={() => handleSort('promised_shipping_time')} style={{ cursor: 'pointer' }}>
              Fecha Compromiso{getSortIcon('promised_shipping_time')}
            </th>
            <th>Estado</th>
            <th onClick={() => handleSort('price')} style={{ cursor: 'pointer' }}>
              Precio{getSortIcon('price')}
            </th>
            <th>Comprado</th>
            <th>Etiqueta</th>
            <th>Listo para despachar</th>
          </tr>
        </thead>
        <tbody>
          {orderItems.map((order) => {
            const { date, time } = formatDateTime(order.created_at);
            const statusLabel = getStatusLabel(order.status);
            const baseColor = statusColors[statusLabel] || '#FFFFFF';
            const backgroundColor = hoveredOrderId === order.order_id ? getHoverColor(baseColor) : baseColor;

            return (
              <React.Fragment key={order.order_id}>
                <tr
                  className={`order-row ${expandedOrderId === order.order_id ? 'expanded' : ''}`}
                  onMouseEnter={() => setHoveredOrderId(order.order_id)}
                  onMouseLeave={() => setHoveredOrderId(null)}
                  onClick={() => toggleExpand(order.order_id)}
                  style={{ cursor: 'pointer' }}
                >
                  <td>{expandedOrderId === order.order_id ? '▼' : '▶'}</td>
                  {/* Aplicamos el color de fondo solo a las columnas especificadas */}
                  <td style={{ backgroundColor }}>{order.order_number}</td>
                  <td style={{ backgroundColor }}>{order.customer_name}</td>
                  <td style={{ backgroundColor }}>{date}</td>
                  <td style={{ backgroundColor }}>{time}</td>
                  <td style={{ backgroundColor }}>
                    {order.promised_shipping_time
                      ? new Date(order.promised_shipping_time).toLocaleDateString('es-CL', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })
                      : '-'}
                  </td>
                  <td style={{ backgroundColor }}>{order.status}</td>
                  <td style={{ backgroundColor }}>{formatPrice(order.price)}</td>
                  {/* Columnas sin color de fondo */}
                  <td className="text-center">
                    <Button
                      className={order.comprado ? 'btn-success' : 'btn-danger'}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePurchaseToggle(order.order_id, order.comprado);
                      }}
                    >
                      {order.comprado ? 'Sí' : 'No'}
                    </Button>
                  </td>
                  <td className="text-center">
                    <Button
                      variant={order.etiqueta ? 'success' : 'primary'}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePreviewLabel(order.order_id, order.order_number); // Pasamos order_number
                      }}
                    >
                      Etiqueta
                    </Button>
                  </td>
                  <td className="text-center">
                    <Button
                      variant={order.ready_to_ship_enabled === 2 ? 'success' : 'primary'}
                      disabled={order.ready_to_ship_enabled !== 1}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleReadyToShip(order.order_id);
                      }}
                    >
                      {order.ready_to_ship_enabled === 2 ? 'Listo' : 'Listo para despachar'}
                    </Button>
                  </td>
                </tr>
                {expandedOrderId === order.order_id && renderItemsRow(order)}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>

      {/* Modal para la vista previa de la etiqueta */}
      <Modal show={showLabelModal} onHide={() => setShowLabelModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Vista Previa de Etiqueta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {labelUrl ? (
            <iframe
              src={labelUrl}
              title="Vista Previa de la Etiqueta"
              style={{ width: '100%', height: '600px' }}
            />
          ) : (
            <p>No se pudo cargar la etiqueta.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLabelModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={downloadLabel}>
            Descargar Etiqueta
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OrderTable;
