// src/components/OrderDetails.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const OrderDetails = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get(`/api/orderItems/${orderId}`);
        setOrder(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching order details:', error);
        setLoading(false);
      }
    };

    fetchOrder();
  }, [orderId]);

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <h1>Order Details</h1>
      {order && (
        <div>
          <h2>Order ID: {orderId}</h2>
          <ul>
            {order.map(item => (
              <li key={item.OrderItemId}>
                <strong>Item Name:</strong> {item.Name}<br />
                <strong>SKU:</strong> {item.Sku}<br />
                <strong>Price:</strong> {item.Price}<br />
                <strong>Status:</strong> {item.Status}<br />
                <strong>Tracking Code:</strong> {item.TrackingCode}<br />
                <strong>Shipment Provider:</strong> {item.ShipmentProvider}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
