import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OrdersList from './components/OrdersList';
import OrderDetails from './components/OrderDetails';
import BarcodeScanner from './components/BarcodeScanner'; // Componente móvil con cámara
import OrderScannerDesktop from './components/OrderScannerDesktop'; // Componente de escritorio

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<OrdersList />} />
          <Route path="/order/:orderId" element={<OrderDetails />} />
          <Route path="/scan" element={<BarcodeScanner />} /> {/* Ruta para la versión móvil */}
          <Route path="/lector" element={<OrderScannerDesktop />} /> {/* Ruta para la versión escritorio */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
