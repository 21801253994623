import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Row, Col } from 'react-bootstrap';
import * as XLSX from 'xlsx';

import DashboardMetrics from './DashboardMetrics';
import OrderFilters from './OrderFilters';
import OrderTable from './OrderTable';
import PaginationControl from './PaginationControl';

function OrdersList() {
  // Estados para filtros
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedPurchased, setSelectedPurchased] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [sortOrder, setSortOrder] = useState({ key: 'created_at', direction: 'desc' });

  // Estados para paginación
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  // Estados para órdenes y carga
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedOrderId, setExpandedOrderId] = useState(null);

  // Función para alternar la expansión de una fila
  const toggleExpand = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  // Función para manejar el cambio de compra
  const handlePurchaseToggle = async (orderId, currentStatus) => {
    try {
      await axios.post(`/api/markAsPurchased/${orderId}`, { comprado: !currentStatus });
      setOrders(orders.map(order =>
        order.order_id === orderId ? { ...order, comprado: !currentStatus } : order
      ));
    } catch (error) {
      console.error('Error al actualizar el estado de compra:', error);
      alert('No se pudo actualizar el estado de compra. Por favor, inténtalo de nuevo.');
    }
  };

  // Función para manejar el cambio de "Listo para despachar"
  const handleReadyToShip = async (orderId) => {
    try {
      await axios.post(`/api/setStatusToReadyToShip/${orderId}`);
      setOrders(orders.map(order =>
        order.order_id === orderId ? { ...order, ready_to_ship_enabled: 2 } : order
      ));
    } catch (error) {
      console.error('Error al marcar como listo para despachar:', error);
      alert('No se pudo marcar como listo para despachar. Por favor, inténtalo de nuevo.');
    }
  };

  // Función para formatear el precio
  const formatPrice = (price) => {
    return price.toLocaleString('es-CL', { style: 'currency', currency: 'CLP', minimumFractionDigits: 0 });
  };

  // Función para formatear fecha y hora
  const formatDateTime = (datetime) => {
    const date = new Date(datetime);
    const formattedDate = date.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const formattedTime = date.toLocaleTimeString('es-CL', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    return { date: formattedDate, time: formattedTime };
  };

  // Función para exportar a Excel
  const exportToExcel = async () => {
    try {
      const response = await axios.get('/api/orders', {
        params: {
          page: 1,
          limit: 1000000,
          sortKey: sortOrder.key,
          sortDirection: sortOrder.direction,
          search: searchTerm,
          status: selectedStatus,
          startDate,
          endDate,
          comprado: selectedPurchased
        }
      });

      const allOrders = response.data.orders;

      const ordersToExport = allOrders.map(order => ({
        'Número de Orden': order.order_number,
        'Cliente': order.customer_name,
        'Fecha de Compra': new Date(order.created_at).toLocaleDateString('es-CL', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        'Fecha Compromiso': order.promised_shipping_time
          ? new Date(order.promised_shipping_time).toLocaleDateString('es-CL', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : '-',
        'Estado': order.status,
        'Precio': formatPrice(order.price),
        'Comprado': order.comprado ? 'Sí' : 'No',
        'Etiqueta': order.etiqueta ? 'Sí' : 'No',
        'Listo para despachar': order.ready_to_ship_enabled === 2 ? 'Sí' : 'No'
      }));

      const worksheet = XLSX.utils.json_to_sheet(ordersToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Órdenes");
      XLSX.writeFile(workbook, "ordenes.xlsx");
    } catch (error) {
      console.error('Error exportando las órdenes:', error.message);
      alert('No se pudo exportar a Excel. Por favor, inténtalo de nuevo.');
    }
  };

  // Función para obtener las órdenes desde la API
  const fetchOrders = async () => {
    setLoading(true);
    try {
      const params = {
        page: currentPage,
        limit: rowsPerPage,
        sortKey: sortOrder.key,
        sortDirection: sortOrder.direction,
        search: searchTerm,
        status: selectedStatus,
        startDate,
        endDate,
        comprado: selectedPurchased
      };

      const response = await axios.get('/api/orders', { params });
      setOrders(response.data.orders);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error obteniendo las órdenes:', error);
      alert('No se pudo obtener las órdenes. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [currentPage, rowsPerPage, selectedStatus, selectedPurchased, sortOrder, startDate, endDate]);

  // Función para manejar la búsqueda al presionar "Enter"
  const handleSearch = () => {
    fetchOrders();
  };

  return (
    <div style={{ width: '90%', margin: 'auto' }}>
      <h2>Lista de Órdenes</h2>
      <DashboardMetrics startDate={startDate} endDate={endDate} />
      <Row className="mb-3">
        <Col>
          <Button variant="success" onClick={exportToExcel}>
            Exportar a Excel
          </Button>
        </Col>
      </Row>
      <OrderFilters
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        selectedPurchased={selectedPurchased}
        setSelectedPurchased={setSelectedPurchased}
        dateRange={dateRange}
        setDateRange={setDateRange}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        handleSearch={handleSearch}  // Pasar la función de búsqueda al componente de filtros
      />
      <OrderTable
        orders={orders}
        loading={loading}
        expandedOrderId={expandedOrderId}
        toggleExpand={toggleExpand}
        formatDateTime={formatDateTime}
        formatPrice={formatPrice}
        handlePurchaseToggle={handlePurchaseToggle}
        handleReadyToShip={handleReadyToShip}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
      />
      <PaginationControl
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
}

export default OrdersList;
